
import {
  onUnmounted,
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import {
  IInformation,
  InformationFilter,
  InformationModel
} from "@/apis/interface/IInformation";

import bus from "@/utils/bus";
import { useStore } from "@/store/index";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const {
      state: {
        authData: { admin }
      }
    } = useStore();
    const apiInformation = inject("apiInformation") as IInformation;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: InformationFilter = reactive({
      title: "",
      content: "",
      sourceType: "", //law, activity, news, report
      serviceBeanId: null,
      serviceKey: "",
      serviceId: null
    });

    let clearFilter = () => {
      filter.title = "";
      filter.content = "";
      filter.sourceType = "";
      filter.serviceBeanId = null;
      filter.serviceKey = "";
      filter.serviceId = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<InformationModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiInformation.getList(pageParams, filter);
      const pager = resp as RespBodyPager<InformationModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new InformationModel({
          creatorAdminId: admin?.id
        })
      });
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let deleteRow = async (id: number) => {
      let result = (await apiInformation.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );

    watch(
      () => pageParams.aaSize,
      () => {
        pageParams.aaIndex = 1;
        loadData();
      }
    );

    let tableHeight = ref(0);

    let getTypeName = (type: string) => {
      switch (type) {
        case "law":
          return "法律";
        case "activity":
          return "活动";
        case "news":
          return "新闻";
        case "report":
          return "报告";
      }
    };

    return {
      loading,
      tableHeight,
      pageParams,
      filter,
      dataList,
      statusFormat,
      clickCreate,
      loadData,
      deleteRow,
      clearFilter,
      getTypeName
    };
  }
});
